import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import video from "../assets/video.mov";
import smallScreen from "../assets/smallScreen.mp4";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export default function SwiperComponents() {
  useEffect(() => {
    AOS.init();
  }, []);
  const handleSlideChange = () => {
    AOS.init();
    AOS.refresh();
  };

  return (
    <>
      <div style={{ position: "relative", width: "100%", height: "50%" }}>
        <video
          className="w-full hidden  md:block"
          height={10}
          controls={false}
          autoPlay={true}
          muted
          loop={true}
          style={{ width: "100%" }}
        >
          <source src={video} />
        </video>
        <video
          className="w-full block  md:hidden"
          controls={false}
          autoPlay={true}
          muted
          loop={true}
          style={{ width: "100%" }}
        >
          <source src={video} />
        </video>
        <div className="hidden sm:block md:flex ">
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "78%",
              transform: "translate(-50%, -50%)",
              color: "white",
              fontSize: "48px",
              fontWeight: "500",
            }}
          >
            Sustainability
            <p className="text-[24px] pt-2 text-[white] font-[400] text-center">
              We are part of Ecological Coating Ink Solutions
            </p>
            <div className="h-[10px]  font-calibri  flex justify-center pt-3">
              <button className=" h-[40px] mt-5 bg-[#79ba44] border border-[#79ba44]  text-[white] text-[18px]  px-5 font-calibri hover:bg-[#f0f1f1] hover:border-[#79ba44] hover:text-[#79ba44]">
                Find Out More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
