import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDropright } from "react-icons/io";

export default function ProductCard({
  text,
  color,
  description,
  productId,
  customLink,
  image
}) {
  // Function to handle the click on the ProductCard
  const title = encodeURIComponent(text);
  const desc = encodeURIComponent(description);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link
      to={`/detail?title=${title}&desc=${desc}&productId=${productId}&image=${image}`}
      onClick={handleClick}
      className="relative flex flex-col w-full h-[350px] md:h-[350px] font-calibri bg-[#f0f1f1] cursor-pointer transform transition-transform duration-100 hover:scale-105"
    >
      <div className="p-4 flex items-center" style={{ backgroundColor: color }}>
        <h2 className="text-white text-[20px] font-[500] font-calibri">
          {text}
        </h2>
      </div>
      <div className="container flex p-4 text-[16px] md:text-[18px] leading-[22px] font-calibri font-[300]">
        {description}
      </div>
      <div
        className="absolute bottom-0 right-0 p-2 text-[14px] md:text-[16px] rounded-tl-lg flex items-center"
        style={{ color: color }}
      >
        Explore <IoIosArrowDropright size={16} className="ml-1" />
      </div>
    </Link>
  );
}
