import React, { useState, useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { AiOutlineClose, AiOutlineMenu, AiOutlinePlus } from "react-icons/ai";
import { FaAngleLeft, FaAngleRight, FaHome } from "react-icons/fa";
import { FaEnvelope, FaMapMarker } from "react-icons/fa"; // Import the icons you need
import logo from "../assets/logo.png";
import whatsapp from "../assets/whatsapp.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaAngleDown, FaAngleUp } from "react-icons/fa"; // Import the icons you want to use
import { TiTick } from "react-icons/ti";
import { Link, useNavigation } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  FiTwitter,
  FiFacebook,
  FiInstagram,
  FiYoutube,
  FiPhone,
  FiMail,
  FiGlobe,
} from "react-icons/fi";

export default function Header({ searchQuery, setSearchQuery, handleSearch }) {
  const [nav, setNav] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searctText, setSearchText] = useState("");
  const handleSearchIconClick = () => {
    if (!nav) {
      setShowSearchBar(!showSearchBar);
    }
  };
  const [showCompanyList, setShowCompanyList] = useState(false);

  const [showOrderByIndustry, setShowOrderByIndustry] = useState(false);
  const [showOrderByApplication, setshowOrderByApplication] = useState(false);

  const [showBoxesByMaterial, setShowBoxesByMaterial] = useState(false);
  const [showBoxesByStyle, setShowBoxesByStyle] = useState(false);
  //   const handleNavigation = () => {
  //     dispatch(searchApiData(searctText))
  //     if (searctText == '') {
  //       return alert("please Enter")
  //     }
  //     route.push(`/Search?id=${searctText}`)
  //   }
  const [languageshow, setShowLanguage] = useState(false);

  const handleLanguageShow = () => {
    setShowLanguage(!languageshow);
  };

  const toggleNav = () => {
    setNav(!nav);
  };

  const toggleCompanyList = () => {
    setShowCompanyList(!showCompanyList);
  };

  const toggleOrderByIndustry = () => {
    setShowOrderByIndustry(!showOrderByIndustry);
  };

  const toggleBoxesByMaterial = () => {
    setShowBoxesByMaterial(!showBoxesByMaterial);
  };

  const toggleBoxesByStyle = () => {
    setShowBoxesByStyle(!showBoxesByStyle);
  };
  const [isFixed, setIsFixed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const [linkText, setLinkText] = useState("");

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scrollThreshold = 200; // Adjust this threshold as needed

      // Check if the scroll position is beyond the threshold
      if (scrollY > scrollThreshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const bottomModel = (text) => {
    setLinkText(text);
    setVisible(!visible);
    setVisible1(false);
    setVisible2(false);
    setVisible3(false);
  };
  const bottomModel1 = (text) => {
    setLinkText(text);
    setVisible1(!visible1);
    setVisible(false);
    setVisible2(false);
    setVisible3(false);
  };
  const bottomModel2 = (text) => {
    setLinkText(text);
    setVisible2(!visible2);
    setVisible1(false);
    setVisible(false);
    setVisible3(false);
  };
  const navigate = useNavigate();

  const bottomModel3 = (text) => {
    setLinkText(text);
    setVisible3(!visible3);
    setVisible(false);
    setVisible1(false);
    setVisible2(false);
  };

  return (
    <>
      <div className="flex flex-wrap ">
        <section className="mx-auto md:w-[100%]">
          {/* Navbar */}
          <nav className="flex justify-between lg:bg-[white] text-[#79ba44] md:w-[100%] my-0 md:my-2">
            <div className="hidden md:flex px-5 xl:px-12 w-full items-center">
              <Link className="text-3xl font-bold font-heading" to="/">
                <img src={logo} alt="logo" style={{ height: 80 }} />
              </Link>
              {/* Nav ps */}
              <ul className=" md:flex px-4 mx-auto font-heading space-x-12">
                <li>
                  <div className="flex items-center mt-2 mx-auto">
                    <p className="  text-[#79ba44] ml-2 font-[600]">Coating</p>
                  </div>
                </li>
                <li>
                  <div className="flex items-center mt-2 mx-auto ">
                    <p className="text-[#79ba44] ml-2">Adhesive</p>
                  </div>
                </li>
              </ul>

              {/* Header Icons */}
              <div className="flex items-center mt-2 mx-auto ">
                <div className="group mt-[10px] relative z-30">
                  <div className="flex" onClick={() => handleLanguageShow()}>
                    <p
                      href="#"
                      className=" font-500 text-[16px] text-[#79ba44] rounded-md px-3 -mt-[3px] "
                    >
                      English
                    </p>{" "}
                    {languageshow ? (
                      <FaAngleDown className="text-black" size={20} />
                    ) : (
                      <FaAngleRight className="text-black" size={20} />
                    )}
                  </div>
                  {languageshow ? (
                    <div className="lg:w-[180px] md:w-[300px] absolute  z-10 mt-2 bg-[white] text-[#79ba44] text-sm rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible flex flex-col md:flex-row w-full">
                      {" "}
                      {/* Set the width to be full */}
                      <div className="flex flex-col md:flex-row">
                        <div className="flex-1 pt-5 font-poppins">
                          <div className="flex">
                            <TiTick className="text-black mt-2" size={20} />
                            <p
                              href="#"
                              className="flex items-center py-2 text-[black] hover:bg-[#79ba44]-700  text-[15px]"
                            >
                              English
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {/* Sign In / Register */}
              </div>
            </div>
            {/* Responsive navbar */}
          </nav>
        </section>
      </div>
      <div className="md:hidden ">
        <div className="flex ">
          <div className="flex w-[50%] bg-white  text-[#79ba44] font-[600] justify-center py-2 pt-1">
            <p className="text-#79ba44 font-600 ml-2 text-center pt-1">
              Coating
            </p>
          </div>
          <div className="flex w-[50%] bg-[#f5f5f5]  text-[#79ba44] mx-auto justify-center py-2 pt-2">
            <p className="text-#79ba44 font-300 ml-2 text-center">Adhesive</p>
          </div>
        </div>
      </div>

      <nav className={` w-full z-10 ${isFixed ? " top-0" : "sticky top-0"}`}>
        <div className="w-full hidden md:block sm:hidden px-2 sm:px-6 lg:px-8 bg-[#F5F5F5]  top-0 font-poppins">
          <div className="relative flex h-14 items-center justify-between">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center"></div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-6 ">
                  {" "}
                  {/* Adjust the spacing here */}
                  <div
                    className="group relative z-10  "
                    style={
                      linkText == "Applications" && visible
                        ? { borderBottom: "5px solid #79ba44" }
                        : null
                    }
                  >
                    <p
                      onClick={() => bottomModel("Applications")}
                      className="text-[#79ba44]  cursor-pointer  rounded-md ml-[-8px] py-4 font-500 text-[16px] hover:text-[#79ba44] flex items-center justify-center"
                    >
                      Applications
                    </p>
                  </div>
                  <div
                    className="group relative z-10  "
                    style={
                      linkText == "Company"
                        ? { borderBottom: "5px solid #79ba44" }
                        : null
                    }
                  >
                    <Link
                      onClick={() => bottomModel3("Company")}
                      className="text-[#79ba44]  cursor-pointer rounded-md px-3 py-4 font-500 text-[16px]  hover:text-[#79ba44] flex items-center justify-center"
                    >
                      Company
                    </Link>
                  </div>
                  <div
                    className="group relative z-10 mt-3 "
                    style={
                      linkText == "Products" && visible1
                        ? { borderBottom: "5px solid #79ba44" }
                        : null
                    }
                  >
                    <p
                      href="#"
                      onClick={() => bottomModel1("Products")}
                      className="text-[#79ba44]  cursor-pointer font-500 text-[16px] rounded-md px-3 pt-[5px]  hover:text-[#79ba44] "
                    >
                      Products
                    </p>
                  </div>
                  <div
                    className="group relative z-10  "
                    style={
                      linkText == "Contact" && visible2
                        ? { borderBottom: "5px solid #79ba44" }
                        : null
                    }
                  >
                    <Link
                      onClick={() => bottomModel2("Contact")}
                      className="text-[#79ba44]  cursor-pointer font-500 text-[16px] rounded-md px-3 py-4  hover:text-[#79ba44] flex items-center justify-center"
                    >
                      Contact
                    </Link>
                  </div>
                  {/* Repeat the above structure for other navigation ps */}
                </div>
              </div>
            </div>

            <div className="hidden sm:hidden  md:block">
              <div className="absolute inset-y-0 right-0 flex pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 ">
                <button
                  type="button"
                  className="relative"
                  onClick={handleSearchIconClick}
                >
                  <span className="absolute -inset-1.5"></span>
                  <BsSearch color="#79ba44" size={25} />
                </button>
                <Link to="mailto:info@bccpakistan.com">
                  <div className="flex ml-7">
                    <p
                      style={{
                        color: "white",
                        backgroundColor: "#79ba44",
                      }}
                      className="px-6 py-4  hidden sm:hidden md:flex font-semibold"
                    >
                      <FaEnvelope
                        style={{
                          color: "white",
                          backgroundColor: "#79ba44",
                          marginRight: "10px",
                        }}
                        size={22} // Set the desired size for the icon
                      />{" "}
                      SEND INQUIRY
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {visible || visible1 || visible2 || visible3 ? (
          <div className="h-[500px] bg-[#79ba44] w-full hidden md:block">
            <div className="flex flex-col md:flex-row">
              {linkText == "Products" ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="flex-1 pt-5 font-poppins ml-10">
                    <Link
                      to={`/detail?title=Water%20Based%20Inks&desc=Water%20based%20inks%20are%20defined%20as%20inks%20that%20use%20water%20as%20the%20main%20solvent%20for%20carrying%20pigment.%20They%20are%20made%20up%20mostly%20of%20acrylic%20resins%20or%20hybrid%20blends%20of%20acrylic%20resin%20and%20polyurethanes.%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Water%20based.2c82b544bf5708a7bd76.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      Water Based Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Plastisol%20Inks&desc=A%20plastisol%20is%20a%20colloidal%20dispension%20of%20small%20polymer%20particles%2C%20usually%20polyvinyl%20chloride%2C%20in%20a%20liquid%20plasticizer.%20When%20heated%20to%20around%20180%20°C%2C%20the%20plastic%20particles%20absorb%20the%20plasticizer%2C%20causing%20them%20to%20swell%20and%20fuse%20together%20forming%20a%20viscous%20gel.&productId=undefined&image=/static/media/Bcc_Plastisol%20Inks.cd23169916e05a3a2e33.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      Plastisol Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Silicone%20Inks&desc=Silicone%20ink%2C%20also%20called%20screen%20printing%20silicone%20or%20silicone-based%20ink%20is%20a%20kind%20of%20environmentally%20protective%20screen%20printing%20ink%20that%20is%20used%20for%20printing%20on%20fabric%20and%20leather.%20for%20example%2C%20on%20T-shirts%20or%20Shoes.%20It%20is%20mainly%20used%20in%20the%20textile%20printing%20industry.&productId=undefined&image=/static/media/Bcc_Silicon%20Inks.2e673deca916f10c4854.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Silicone Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Auxiliaries&desc=%20Auxiliaries%20are%20additives%20that%20enhance%20ink%20properties%2C%20such%20as%20viscosity%2C%20adhesion%2C%20or%20drying%20time%2C%20optimizing%20printing%20processes.&productId=undefined&image=/static/media/Bcc_Auxiliaries.e0a31e74ff31a6455812.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Auxiliaries <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Eco%20Series&desc=A%20PVC%20free%20screen%20printing%20ink%20that%20works%20like%20plastisol%2C%20thereby%20offering%20the%20ease%20of%20use%20and%20many%20other%20advantages%20that%20come%20from%20plastisol%2C%20without%20any%20PVC%2C%20phthalates%2C%20or%20any%20other%20harmful%20substances.&productId=undefined&image=/static/media/Bcc_Eco.0b13925a3343429fcf3d.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      Eco Series <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Special%20Effects&desc=Special%20Effects%20refer%20to%20unique%20visual%20enhancements%20like%20metallic%20finishes%2C%20holography%2C%20or%20fluorescence%2C%20elevating%20print%20aesthetics.&productId=undefined&image=/static/media/Bcc_Special%20Effets.d4b953e082e2988ffc89.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Special Effects <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Fluorescent%20Inks&desc=%20Fluorescent%20inks%20are%20pigmented%20printing%20inks%20that%20emit%20vibrant%2C%20highly%20visible%20colors%20under%20ultraviolet%20(UV)%20light%2C%20commonly%20used%20for%20attention-grabbing%20signage%20and%20promotional%20materials.&productId=undefined&image=/static/media/Bcc_Fluorescent%20Inks.cde78584d6479c4ae9cf.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Fluorescent Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                  </div>
                  <div className="flex-1 pt-5 font-poppins ml-10">
                    <Link
                      to={`/detail/product?title=processcmk`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Process CMYK Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail/product?title=discharge`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Discharge Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail/product?title=inks`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Ink additives <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Solvent%20based%20ink&desc=Solvent-based%20ink%20is%20a%20type%20of%20ink%20formulation%20where%20solvents%20serve%20as%20the%20primary%20carrier%2C%20evaporating%20upon%20drying%2C%20commonly%20used%20in%20printing%20for%20its%20fast-drying%20properties%20and%20versatility%20on%20various%20substrates.&productId=undefined&image=/static/media/Bcc_Solvent.f3cbac5d667495d22169.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Solvent based ink <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Heat%20Transfer%20Powders&desc=Heat%20transfer%20powders%20are%20fine%20particles%20used%20in%20transfer%20printing%20processes%2C%20activated%20by%20heat%20to%20adhere%20designs%20onto%20substrates%20such%20as%20textiles%20or%20ceramics.%20%20%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Transfer%20Powder.709176426c6d9e70aee6.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px] w-[400px]"
                    >
                      Heat Transfer Powders
                      <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail/product?title=transfersheet`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]  w-[400px]"
                    >
                      Heat Transfer Sheets{" "}
                      <IoIosArrowForward className="mr-1" />
                    </Link>
                  </div>
                </div>
              ) : linkText == "Applications" ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="flex-1 pt-5 font-poppins ml-10">
                    <Link
                      to={`/detail?title=Auxiliaries&desc=%20Auxiliaries%20are%20additives%20that%20enhance%20ink%20properties%2C%20such%20as%20viscosity%2C%20adhesion%2C%20or%20drying%20time%2C%20optimizing%20printing%20processes.&productId=undefined&image=/static/media/Bcc_Auxiliaries.e0a31e74ff31a6455812.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Auxiliaries <IoIosArrowForward className="mr-1" />
                    </Link>

                    <Link
                      to={`/detail?title=Water%20Based%20Inks&desc=Water%20based%20inks%20are%20defined%20as%20inks%20that%20use%20water%20as%20the%20main%20solvent%20for%20carrying%20pigment.%20They%20are%20made%20up%20mostly%20of%20acrylic%20resins%20or%20hybrid%20blends%20of%20acrylic%20resin%20and%20polyurethanes.%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Water%20based.2c82b544bf5708a7bd76.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      Water Based Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Solvent%20based%20ink&desc=Solvent-based%20ink%20is%20a%20type%20of%20ink%20formulation%20where%20solvents%20serve%20as%20the%20primary%20carrier%2C%20evaporating%20upon%20drying%2C%20commonly%20used%20in%20printing%20for%20its%20fast-drying%20properties%20and%20versatility%20on%20various%20substrates.&productId=undefined&image=/static/media/Bcc_Solvent.f3cbac5d667495d22169.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Solvent based ink <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Plastisol%20Inks&desc=A%20plastisol%20is%20a%20colloidal%20dispension%20of%20small%20polymer%20particles%2C%20usually%20polyvinyl%20chloride%2C%20in%20a%20liquid%20plasticizer.%20When%20heated%20to%20around%20180%20°C%2C%20the%20plastic%20particles%20absorb%20the%20plasticizer%2C%20causing%20them%20to%20swell%20and%20fuse%20together%20forming%20a%20viscous%20gel.&productId=undefined&image=/static/media/Bcc_Plastisol%20Inks.cd23169916e05a3a2e33.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      Plastisol Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail?title=Silicone%20Inks&desc=Silicone%20ink%2C%20also%20called%20screen%20printing%20silicone%20or%20silicone-based%20ink%20is%20a%20kind%20of%20environmentally%20protective%20screen%20printing%20ink%20that%20is%20used%20for%20printing%20on%20fabric%20and%20leather.%20for%20example%2C%20on%20T-shirts%20or%20Shoes.%20It%20is%20mainly%20used%20in%20the%20textile%20printing%20industry.&productId=undefined&image=/static/media/Bcc_Silicon%20Inks.2e673deca916f10c4854.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Silicone Inks <IoIosArrowForward className="mr-1" />
                    </Link>

                    <Link
                      to={`/detail?title=Eco%20Series&desc=A%20PVC%20free%20screen%20printing%20ink%20that%20works%20like%20plastisol%2C%20thereby%20offering%20the%20ease%20of%20use%20and%20many%20other%20advantages%20that%20come%20from%20plastisol%2C%20without%20any%20PVC%2C%20phthalates%2C%20or%20any%20other%20harmful%20substances.&productId=undefined&image=/static/media/Bcc_Eco.0b13925a3343429fcf3d.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      Eco Series <IoIosArrowForward className="mr-1" />
                    </Link>

                    <Link
                      to={`/detail?title=Fluorescent%20Inks&desc=%20Fluorescent%20inks%20are%20pigmented%20printing%20inks%20that%20emit%20vibrant%2C%20highly%20visible%20colors%20under%20ultraviolet%20(UV)%20light%2C%20commonly%20used%20for%20attention-grabbing%20signage%20and%20promotional%20materials.&productId=undefined&image=/static/media/Bcc_Fluorescent%20Inks.cde78584d6479c4ae9cf.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Fluorescent Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                  
                  </div>
                  <div className="flex-1 pt-5 font-poppins ml-10">
                  <Link
                  to={`/detail?title=Special%20Effects&desc=Special%20Effects%20refer%20to%20unique%20visual%20enhancements%20like%20metallic%20finishes%2C%20holography%2C%20or%20fluorescence%2C%20elevating%20print%20aesthetics.&productId=undefined&image=/static/media/Bcc_Special%20Effets.d4b953e082e2988ffc89.png`}
                  className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                >
                  Special Effects <IoIosArrowForward className="mr-1" />
                </Link>
                    <Link
                      to={`/detail/product?title=processcmk`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Process CMYK Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail/product?title=discharge`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Discharge Inks <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail/product?title=inks`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]"
                    >
                      Ink additives <IoIosArrowForward className="mr-1" />
                    </Link>

                    <Link
                      to={`/detail?title=Heat%20Transfer%20Powders&desc=Heat%20transfer%20powders%20are%20fine%20particles%20used%20in%20transfer%20printing%20processes%2C%20activated%20by%20heat%20to%20adhere%20designs%20onto%20substrates%20such%20as%20textiles%20or%20ceramics.%20%20%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Transfer%20Powder.709176426c6d9e70aee6.png`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]  w-[400px]"
                    >
                      Heat Transfer Powders
                      <IoIosArrowForward className="mr-1" />
                    </Link>
                    <Link
                      to={`/detail/product?title=transfersheet`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700 text-[15px]  w-[400px]"
                    >
                      Heat Transfer Sheets{" "}
                      <IoIosArrowForward className="mr-1" />
                    </Link>
                  </div>
                </div>
              ) : linkText == "Contact" ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: 60,
                    margin: "auto",
                    paddingTop: 100,
                  }}
                >
                  <div className="w-[280px] h-[180px]  border border-white p-4 rounded-md shadow-md mr-5 ">
                    {/* Content inside the box */}
                    <div className="mt-20">
                      <Link
                        to="mailto:info@bccpakistan.com"
                        style={{ color: "white" }}
                      >
                        <FiMail size={36} /> General Contact
                      </Link>
                    </div>
                  </div>
                  <div className="w-[280px] h-[180px]  border border-white p-4 rounded-md shadow-md mr-5 ">
                    {/* Content inside the box */}
                    <div className="mt-20">
                      <Link
                        to="tel:+923007211816"
                        className="mt-10"
                        style={{ color: "white" }}
                      >
                        <FiPhone size={36} /> Phone Number
                      </Link>
                    </div>
                  </div>
                  <div className="w-[280px] h-[180px]  border border-white p-4 rounded-md shadow-md">
                    {/* Content inside the box */}
                    <div className="mt-20">
                      <Link
                        to="https://wa.me/+923007211816"
                        style={{ color: "white" }}
                      >
                        <FaWhatsapp size={36} /> WhatsApp
                      </Link>
                    </div>
                  </div>
                </div>
              ) : linkText == "Company" ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div className="flex-1 pt-5 font-poppins ml-10">
                    <Link
                      to={`/detailCompany`}
                      className="flex items-center px-4 py-2 text-[white] hover:bg-[#79ba44]-700  text-[15px]"
                    >
                      About <IoIosArrowForward className="mr-1" />
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="md:hidden font-poppins">
          <div className="flex items-center justify-between space-x-5 bg-[white] px-5">
            {/* Menu Icon (left side) */}
            {/* Logo (right side) */}
            <Link to="/" className="text-black font-bold text-xl mb-2">
              <img src={logo} alt="logo" style={{ height: "50px" }} />
            </Link>
            {/* WhatsApp p (right side) */}
            <div className="flex justify-between space-x-2">
              <button
                type="button"
                className="relative"
                onClick={handleSearchIconClick}
              >
                <span className="absolute -inset-1.5"></span>
                <BsSearch color="black" size={22} />
              </button>
              {nav ? (
                <AiOutlineClose
                  size={20}
                  onClick={toggleNav}
                  className="mt-1"
                />
              ) : (
                <AiOutlineMenu onClick={toggleNav} size={25} />
              )}
            </div>
          </div>
          <ul
            className={
              nav
                ? "fixed top-0 left-0 w-full h-full bg-[#F5F5F5]  ease-in-out duration-500 z-10 flex flex-col"
                : "fixed top-16 left-[-170%]"
            }
          >
            <div className="  ml-auto mt-10 mr-5">
              <AiOutlineClose size={30} color="#79ba44" onClick={toggleNav} />
            </div>
            <Link to="mailto:info@bccpakistan.com">
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#79ba44",
                  padding: "12px",
                  fontWeight: "600",
                }}
              >
                <FaEnvelope
                  style={{
                    color: "#79ba44",
                    marginRight: "10px",
                    fontSize: "22px",
                  }}
                />
                SEND INQUIRY
              </p>
            </Link>

            <div className="scrollable-dropdown overflow-y-auto max-h-[100vh]">
              <li
                className="p-4 border-b text-center border-[#79ba44] text-[#79ba44] mx-10 cursor-pointer"
                onClick={() =>
                  setshowOrderByApplication(!showOrderByApplication)
                }
              >
                <p className="flex justify-between items-center">
                  <span className=" font-semibold text-[#79ba44]">
                    Applications
                  </span>
                  <span>
                    {showOrderByApplication ? (
                      <FaAngleUp className="text-[#79ba44]" size={20} />
                    ) : (
                      <FaAngleDown className="text-[#79ba44]" size={20} />
                    )}
                  </span>
                </p>
              </li>
              {showOrderByApplication && (
                <div className="mx-10">
                  <div className="flex-1 pt-2 mx-auto">
                    <Link
                      to={`/detail?title=Auxiliaries&desc=%20Auxiliaries%20are%20additives%20that%20enhance%20ink%20properties%2C%20such%20as%20viscosity%2C%20adhesion%2C%20or%20drying%20time%2C%20optimizing%20printing%20processes.&productId=undefined&image=/static/media/Bcc_Auxiliaries.e0a31e74ff31a6455812.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Auxiliaries <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Water%20Based%20Inks&desc=Water%20based%20inks%20are%20defined%20as%20inks%20that%20use%20water%20as%20the%20main%20solvent%20for%20carrying%20pigment.%20They%20are%20made%20up%20mostly%20of%20acrylic%20resins%20or%20hybrid%20blends%20of%20acrylic%20resin%20and%20polyurethanes.%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Water%20based.2c82b544bf5708a7bd76.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Water Based Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Solvent%20based%20ink&desc=Solvent-based%20ink%20is%20a%20type%20of%20ink%20formulation%20where%20solvents%20serve%20as%20the%20primary%20carrier%2C%20evaporating%20upon%20drying%2C%20commonly%20used%20in%20printing%20for%20its%20fast-drying%20properties%20and%20versatility%20on%20various%20substrates.&productId=undefined&image=/static/media/Bcc_Solvent.f3cbac5d667495d22169.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Solvent based ink <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Plastisol%20Inks&desc=A%20plastisol%20is%20a%20colloidal%20dispension%20of%20small%20polymer%20particles%2C%20usually%20polyvinyl%20chloride%2C%20in%20a%20liquid%20plasticizer.%20When%20heated%20to%20around%20180%20°C%2C%20the%20plastic%20particles%20absorb%20the%20plasticizer%2C%20causing%20them%20to%20swell%20and%20fuse%20together%20forming%20a%20viscous%20gel.&productId=undefined&image=/static/media/Bcc_Plastisol%20Inks.cd23169916e05a3a2e33.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Plastisol Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Silicone%20Inks&desc=Silicone%20ink%2C%20also%20called%20screen%20printing%20silicone%20or%20silicone-based%20ink%20is%20a%20kind%20of%20environmentally%20protective%20screen%20printing%20ink%20that%20is%20used%20for%20printing%20on%20fabric%20and%20leather.%20for%20example%2C%20on%20T-shirts%20or%20Shoes.%20It%20is%20mainly%20used%20in%20the%20textile%20printing%20industry.&productId=undefined&image=/static/media/Bcc_Silicon%20Inks.2e673deca916f10c4854.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Silicone Inks <IoIosArrowForward className="ml-2" />
                    </Link>

                    <Link
                      to={`/detail?title=Eco%20Series&desc=A%20PVC%20free%20screen%20printing%20ink%20that%20works%20like%20plastisol%2C%20thereby%20offering%20the%20ease%20of%20use%20and%20many%20other%20advantages%20that%20come%20from%20plastisol%2C%20without%20any%20PVC%2C%20phthalates%2C%20or%20any%20other%20harmful%20substances.&productId=undefined&image=/static/media/Bcc_Eco.0b13925a3343429fcf3d.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Eco Series <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Special%20Effects&desc=Special%20Effects%20refer%20to%20unique%20visual%20enhancements%20like%20metallic%20finishes%2C%20holography%2C%20or%20fluorescence%2C%20elevating%20print%20aesthetics.&productId=undefined&image=/static/media/Bcc_Special%20Effets.d4b953e082e2988ffc89.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Special Effects <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Fluorescent%20Inks&desc=%20Fluorescent%20inks%20are%20pigmented%20printing%20inks%20that%20emit%20vibrant%2C%20highly%20visible%20colors%20under%20ultraviolet%20(UV)%20light%2C%20commonly%20used%20for%20attention-grabbing%20signage%20and%20promotional%20materials.&productId=undefined&image=/static/media/Bcc_Fluorescent%20Inks.cde78584d6479c4ae9cf.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Fluorescent Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=processcmk`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Process CMYK Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=discharge`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Discharge Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=inks`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Ink additives <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Heat%20Transfer%20Powders&desc=Heat%20transfer%20powders%20are%20fine%20particles%20used%20in%20transfer%20printing%20processes%2C%20activated%20by%20heat%20to%20adhere%20designs%20onto%20substrates%20such%20as%20textiles%20or%20ceramics.%20%20%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Transfer%20Powder.709176426c6d9e70aee6.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Heat Transfer Powders{" "}
                      <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=transfersheet`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Heat Transfer Sheets{" "}
                      <IoIosArrowForward className="ml-2" />
                    </Link>
                  </div>
                </div>
              )}
              <li className="p-4 border-b  border-[#79ba44] font-semibold text-[#79ba44]  mx-10 ">
                <Link to={`/detailCompany`}>Company</Link>
              </li>

              <li
                className="p-4 border-b text-center border-[#79ba44] text-[#79ba44] mx-10 cursor-pointer"
                onClick={() => setShowOrderByIndustry(!showOrderByIndustry)}
              >
                <p className="flex justify-between items-center">
                  <span className=" font-semibold text-[#79ba44]">
                    Products
                  </span>
                  <span>
                    {showOrderByIndustry ? (
                      <FaAngleUp className="text-[#79ba44]" size={20} />
                    ) : (
                      <FaAngleDown className="text-[#79ba44]" size={20} />
                    )}
                  </span>
                </p>
              </li>
              {showOrderByIndustry && (
                <div className="mx-10">
                  <div className="flex-1 pt-2 mx-auto">
                    <Link
                      to={`/detail?title=Water%20Based%20Inks&desc=Water%20based%20inks%20are%20defined%20as%20inks%20that%20use%20water%20as%20the%20main%20solvent%20for%20carrying%20pigment.%20They%20are%20made%20up%20mostly%20of%20acrylic%20resins%20or%20hybrid%20blends%20of%20acrylic%20resin%20and%20polyurethanes.%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Water%20based.2c82b544bf5708a7bd76.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Water Based Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Plastisol%20Inks&desc=A%20plastisol%20is%20a%20colloidal%20dispension%20of%20small%20polymer%20particles%2C%20usually%20polyvinyl%20chloride%2C%20in%20a%20liquid%20plasticizer.%20When%20heated%20to%20around%20180%20°C%2C%20the%20plastic%20particles%20absorb%20the%20plasticizer%2C%20causing%20them%20to%20swell%20and%20fuse%20together%20forming%20a%20viscous%20gel.&productId=undefined&image=/static/media/Bcc_Plastisol%20Inks.cd23169916e05a3a2e33.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Plastisol Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Silicone%20Inks&desc=Silicone%20ink%2C%20also%20called%20screen%20printing%20silicone%20or%20silicone-based%20ink%20is%20a%20kind%20of%20environmentally%20protective%20screen%20printing%20ink%20that%20is%20used%20for%20printing%20on%20fabric%20and%20leather.%20for%20example%2C%20on%20T-shirts%20or%20Shoes.%20It%20is%20mainly%20used%20in%20the%20textile%20printing%20industry.&productId=undefined&image=/static/media/Bcc_Silicon%20Inks.2e673deca916f10c4854.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Silicone Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Auxiliaries&desc=%20Auxiliaries%20are%20additives%20that%20enhance%20ink%20properties%2C%20such%20as%20viscosity%2C%20adhesion%2C%20or%20drying%20time%2C%20optimizing%20printing%20processes.&productId=undefined&image=/static/media/Bcc_Auxiliaries.e0a31e74ff31a6455812.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Auxiliaries <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Eco%20Series&desc=A%20PVC%20free%20screen%20printing%20ink%20that%20works%20like%20plastisol%2C%20thereby%20offering%20the%20ease%20of%20use%20and%20many%20other%20advantages%20that%20come%20from%20plastisol%2C%20without%20any%20PVC%2C%20phthalates%2C%20or%20any%20other%20harmful%20substances.&productId=undefined&image=/static/media/Bcc_Eco.0b13925a3343429fcf3d.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Eco Series <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Special%20Effects&desc=Special%20Effects%20refer%20to%20unique%20visual%20enhancements%20like%20metallic%20finishes%2C%20holography%2C%20or%20fluorescence%2C%20elevating%20print%20aesthetics.&productId=undefined&image=/static/media/Bcc_Special%20Effets.d4b953e082e2988ffc89.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Special Effects <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Fluorescent%20Inks&desc=%20Fluorescent%20inks%20are%20pigmented%20printing%20inks%20that%20emit%20vibrant%2C%20highly%20visible%20colors%20under%20ultraviolet%20(UV)%20light%2C%20commonly%20used%20for%20attention-grabbing%20signage%20and%20promotional%20materials.&productId=undefined&image=/static/media/Bcc_Fluorescent%20Inks.cde78584d6479c4ae9cf.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Fluorescent Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=processcmk`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Process CMYK Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=discharge`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Discharge Inks <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=inks`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Ink additives <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Solvent%20based%20ink&desc=Solvent-based%20ink%20is%20a%20type%20of%20ink%20formulation%20where%20solvents%20serve%20as%20the%20primary%20carrier%2C%20evaporating%20upon%20drying%2C%20commonly%20used%20in%20printing%20for%20its%20fast-drying%20properties%20and%20versatility%20on%20various%20substrates.&productId=undefined&image=/static/media/Bcc_Solvent.f3cbac5d667495d22169.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Solvent based ink <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail?title=Heat%20Transfer%20Powders&desc=Heat%20transfer%20powders%20are%20fine%20particles%20used%20in%20transfer%20printing%20processes%2C%20activated%20by%20heat%20to%20adhere%20designs%20onto%20substrates%20such%20as%20textiles%20or%20ceramics.%20%20%20%20%20%20%20%20%20%20%20%20&productId=undefined&image=/static/media/Bcc_Transfer%20Powder.709176426c6d9e70aee6.png`}
                      onClick={() => toggleNav()}
                      className="flex items-center   px-4 py-2 text-[#79ba44] hover:bg-[#79ba44]-700  text-[14px]  w-[400px] "
                    >
                      Heat Transfer Powders{" "}
                      <IoIosArrowForward className="ml-2" />
                    </Link>
                    <Link
                      to={`/detail/product?title=transfersheet`}
                      onClick={() => toggleNav()}
                      className="flex items-center px-4 py-2 text-[[#79ba44]] hover:bg-[#79ba44]-700  text-[14px]"
                    >
                      Heat Transfer Sheets{" "}
                      <IoIosArrowForward className="ml-2" />
                    </Link>
                  </div>
                </div>
              )}

              <li className="p-4  font-semibold   text-[#79ba44]  mx-10 ">
                <Link to="mailto:info@bccpakistan.com">Contact</Link>
              </li>
            </div>
          </ul>
        </div>

        {showSearchBar && !nav && (
          <div className="flex justify-center items-center shadow-md rounded-lg bg-[white]  ">
            <div className="bg-[white] p-4 w-80">
              <div className="mb-3">
                <div
                  className=" mb-4 flex w-full flex-wrap items-stretch "
                  style={{ borderRadius: "100%" }}
                >
                  <input
                    type="search"
                    className=" m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid  bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-neutral-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:focus:border-primary"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="button-addon1"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />

                  <button
                    className=" flex items-center rounded-r border-black px-6 py-2.5 text-xs font-medium uppercase leading-tight text-[#79ba44] transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                    type="button"
                    id="button-addon1"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                  // onClick={() => handleSearch(searchQuery)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-5 w-5 text-black"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
